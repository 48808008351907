import * as React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import RightPanel from './components/RightPanel';
import Agency from './pages/Agency';
import Client from './pages/Client';
import Dashboard from './pages/Dashboard';
import Department from './pages/Department';
import ForgotPassword from './pages/ForgotPassword';
import LeftPanel from './components/LeftPanel';
import LoginPage from './pages/Login';
import Region from './pages/Region';
import ResetPassword from './pages/ResetPassword';
import Role from './pages/Role';
import Shift from './pages/Shift';
import Site from './pages/Sites';
import Worker from './pages/Worker';
import { getCookie } from './utils/cookie';
import { PATH, TOKENS } from './constants';
import theme, { colors } from './theme';
import Associations from './pages/Associations';
import Users from './pages/Users';
import Attendance from './pages/Attendance';
import RateCard from './pages/RateCard';
import AttendanceDetails from './pages/AttendanceDetails';
import ShiftBooking from './pages/ShiftBooking';
import ProcessedAttendance from './pages/ProcessedAttendance';
import ViewMore from './pages/ViewMore';
import Demographics from './pages/Demographics';
import Messaging from './pages/Messaging';
import UpdateSurvey from './pages/UpdateSurvey';
import Surveys from './pages/Surveys';
import Compliance from './pages/Compliance';
import NotFoundPage from './pages/404PageNotFound';
import Fonts from './styles/Global.style';
import TrendAnalysis from './pages/TrendAnalysis';
import Payroll from './pages/Payroll';
import MessagingTranslate from './pages/MessagingTranslate';
import AutomatedMessageTranslate from './pages/AutomatedMessageTranslate';
import DesignMessageTranslate from './pages/DesignMessageTranslate';

const Layout = function ({ auth, Component, ...props }) {
  return (
    <Flex bg="main.primary" height="100%">
      <LeftPanel />
      <RightPanel>
        <Header />
        <Component {...props} />
      </RightPanel>
    </Flex>
  );
};
Layout.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  auth: PropTypes.bool
};
Layout.defaultProps = {
  Component: null,
  auth: false
};
let auth = !!getCookie(TOKENS.ACCESS);
const RequireAuth = function () {
  auth = !!getCookie(TOKENS.ACCESS);
  if (!auth) {
    window.location.href = PATH.LOGIN.TO;
    return null;
  }
  return <Outlet />;
};
const App = function () {
  return (
    <ThemeProvider theme={{ color: colors }}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path={PATH.LOGIN.TO}
              element={auth ? <Navigate replace to={PATH.DASHBOARD.TO} /> : <LoginPage />}
            />
            <Route exact path={PATH.FORGOT_PASSWORD.TO} element={<ForgotPassword />} />
            <Route exact path={PATH.RESET_PASSWORD.TO} element={<ResetPassword />} />
            <Route path={PATH.LOGIN.TO} element={<RequireAuth />}>
              <Route
                exact
                path={PATH.DASHBOARD.TO}
                element={<Layout auth={auth} Component={Dashboard} />}
              />
              <Route
                exact
                path={PATH.REGION.TO}
                element={<Layout auth={auth} Component={Region} />}
              />
              <Route exact path={PATH.SITES.TO} element={<Layout auth={auth} Component={Site} />} />
              <Route
                exact
                path={PATH.DEPARTMENT.TO}
                element={<Layout auth={auth} Component={Department} />}
              />
              <Route
                exact
                path={PATH.SHIFT.TO}
                element={<Layout auth={auth} Component={Shift} />}
              />
              <Route exact path={PATH.ROLE.TO} element={<Layout auth={auth} Component={Role} />} />
              <Route
                exact
                path={PATH.AGENCY.TO}
                element={<Layout auth={auth} Component={Agency} />}
              />
              <Route exact path={PATH.CLIENT.TO} element={<Layout Component={Client} />} />
              <Route exact path={PATH.WORKER.TO} element={<Layout Component={Worker} />} />
              <Route
                exact
                path={PATH.AGENCY_CLIENT.TO}
                element={<Layout auth={auth} Component={Associations} />}
              />
              <Route
                exact
                path={PATH.CLIENT_USERS.TO}
                element={<Layout auth={auth} Component={Users} />}
              />
              <Route
                exact
                path={PATH.TIME_AND_ATTENDANCE.TO}
                element={<Layout auth={auth} Component={Attendance} />}
              />
              <Route
                exact
                path={PATH.RATE_CARD.TO}
                element={<Layout auth={auth} Component={RateCard} />}
              />
              <Route
                exact
                path={`${PATH.TIME_AND_ATTENDANCE_DETAILS.TO}/:id`}
                element={<Layout auth={auth} Component={AttendanceDetails} />}
              />
              <Route
                exact
                path={`${PATH.SHIFT_BOOKING.TO}`}
                element={<Layout auth={auth} Component={ShiftBooking} />}
              />
              <Route
                exact
                path={`${PATH.PROCESSED_ATTENDANCE.TO}/:id`}
                element={<Layout auth={auth} Component={ProcessedAttendance} />}
              />
              <Route
                exact
                path={`${PATH.VIEW_MORE.TO}/:section`}
                element={<Layout auth={auth} Component={ViewMore} />}
              />
              <Route
                exact
                path={`${PATH.DEMOGRAPHICS.TO}`}
                element={<Layout auth={auth} Component={Demographics} />}
              />
              <Route
                exact
                path={`${PATH.MESSAGING.TO}`}
                element={<Layout auth={auth} Component={Messaging} />}
              />
              <Route exact path="/messaging-translate" element={<MessagingTranslate />} />
              <Route
                exact
                path="/automated-messaging-translate"
                element={<AutomatedMessageTranslate />}
              />
              <Route exact path="/design-template-translate" element={<DesignMessageTranslate />} />
              <Route
                exact
                path={`${PATH.SURVEYS.TO}`}
                element={<Layout auth={auth} Component={Surveys} />}
              />
              <Route
                exact
                path={`${PATH.TREND_ANALYSIS.TO}`}
                element={<Layout auth={auth} Component={TrendAnalysis} />}
              />
              <Route
                exact
                path={`${PATH.PAYROLL.TO}`}
                element={<Layout auth={auth} Component={Payroll} />}
              />
              <Route
                exact
                path={`${PATH.SURVEY_UPDATE.TO}`}
                element={<Layout auth={auth} Component={UpdateSurvey} />}
              />
              <Route
                exact
                path={`${PATH.COMPLIANCE.TO}`}
                element={<Layout auth={auth} Component={Compliance} />}
              />
              <Route path={`${PATH.NOT_FOUND_PAGE.TO}`} element={<NotFoundPage loggedIn />} />
            </Route>
            {/* <Route exact path={`${PATH.NOT_FOUND_PAGE.TO}`} element={<NotFoundPage loggedIn={false} />} /> */}
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </ThemeProvider>
  );
};
export default App;
