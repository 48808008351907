import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getSites = async (clientId, regionId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SITE}?client_id=${clientId}${regionId ? `&region_id=${regionId}` : ''}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addSite = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_SITE, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateSite = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_SITE}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteDropDownData = async (clientId, regionId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SITE}?client_id=${clientId}${regionId ? `&region_id=${regionId}` : ''}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export { getSites, addSite, updateSite, getSiteDropDownData };
