/* eslint-disable no-useless-escape */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
// import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { DATE_TO_SHOW } from '../constants';
// import moment from 'moment';

export const checkActiveItem = (path) => {
  return path === useLocation().pathname;
};

export const allowOnlyAlphabate = (text) => {
  // alphabate & space
  return text.replace(/[^a-zA-Z\s]+/gim, '');
};

export const allowOnlyAToZ = (text) => {
  // only alphabates
  return text.replace(/[^A-Za-z]+$/, '');
};

export const restrictSomeSpecialCharacter = (text) => {
  return text.replace(/["'\\]/g, '');
};

export const allowOnlyAlphabetsAndNumerics = (text) => {
  //  only alphabets, numerics
  return text.replace(/[!@#$%^&*()+\s_\-\=\[\]{};':"\\|,.<>\/?~`]/g, '');
};
export const replaceEmojiInInputField = (text) => {
  // remove emoji
  const replaceEmoji = text.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '');
  return replaceEmoji;
};

export const allowOnlyNumber = (text) => {
  // only numbers
  return text.replace(/[^\d]+/gim, '').replace(/^0{1,}\d+/, '0');
};

export const allowAlphaNumeric = (text) => {
  // only alphabates & numbers
  return text.replace(/[^a-zA-Z\d\s]+/gim, '');
};

export const validateEmail = (text) => {
  return text.match(/^([a-zA-Z]+[0-9+._-]*)+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}/g) !== null;
};
// export const validatAlphaNumeric = (text) => {
//   return text.match(/^[1-9]\d*(\.\d+)?$/g);
// };

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s])(?!.*\s).{8,}$/;
  return passwordRegex.test(password);
};

export const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const parseSectors = (items) => {
  if (items.length > 0) {
    return items.map((el) => ({
      label: el.name,
      value: el.id
    }));
  }
  return [];
};

export const validateObject = (validationObject, data) => {
  let isFormValid = true;

  Object.keys(validationObject).map((key) => {
    if (validationObject[`${key}`].required && !data[`${key}`]) {
      validationObject[`${key}`].isvalid = false;
      isFormValid = false;
    } else if (
      validationObject[`${key}`].required &&
      data[`${key}`] &&
      Array.isArray(data[`${key}`]) &&
      data[`${key}`].length === 0
    ) {
      validationObject[`${key}`].isvalid = false;
      isFormValid = false;
    } else if (
      validationObject[`${key}`].minlength &&
      data[`${key}`] &&
      data[`${key}`].length < validationObject[`${key}`].minlength
    ) {
      validationObject[`${key}`].isvalid = false;
      isFormValid = false;
    } else if (validationObject[`${key}`].isEmail && !validateEmail(data[`${key}`])) {
      validationObject[`${key}`].isvalid = false;
      isFormValid = false;
    }
    // else if (
    //   validationObject[`${key}`].greaterThanZero &&
    //   (data[`${key}`] === '0' || data[`${key}`] === '0.00')
    // ) {
    //   validationObject[`${key}`].isvalid = false;
    //   isFormValid = false;
    // }
    else {
      validationObject[`${key}`].isvalid = true;
    }
  });

  return [validationObject, isFormValid];
};

export const validateForm = (data, validationData) => {
  const tempData = { ...validationData };
  const [validationObject, isFormValid] = validateObject(tempData, data);
  return [validationObject, isFormValid];
};

const isObject = (obj) => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};

export const deepClone = (src) => {
  const target = {};
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      if (isObject(src[prop])) {
        target[prop] = deepClone(src[prop]);
      } else {
        target[prop] = src[prop];
      }
    }
  }
  return target;
};

export const formatDate = (date) => {
  return DATE_TO_SHOW(date);
  // debugger;
  // let newDate = new Date(date);
  // return moment(date).format('yyyy/mm/DD');
};

export function toFormData(payload) {
  const data = new FormData();
  for (const key of Object.keys(payload)) {
    data.append(key, payload[key]);
  }
  return data;
}

export const allowTwoDecimalNumbers = (text) => {
  // Allowed only two decimal digits.
  // let newText = !!text && Math.abs(text) >= 0 ? Math.abs(text) : null;
  let newText = text;
  if (newText && newText.toString().length) {
    newText = newText.toString();
    return newText.replace(/^(\d+.?\d{0,2})\d*$/, '$1');
  }
  return newText;
};

export const allowTwoDecimalNumbersInRange = (text, min = 0, max = 100) => {
  // Allowed only two decimal digits and values between min and max.
  let newText = text;
  if (newText && newText.toString().length) {
    newText = newText.toString();
    // Remove any non-numeric characters except decimal point
    newText = newText.replace(/[^0-9.]/g, '');
    // If input starts with a decimal point, prepend '0' to it
    if (newText.charAt(0) === '.') {
      newText = `0${newText}`;
    }
    // Ensure the value is between min and max
    const numericValue = parseFloat(newText);
    if (!Number.isNaN(numericValue)) {
      if (numericValue < min) {
        newText = '0.00';
      } else if (numericValue > max) {
        newText = '100.00';
      } else {
        // Allow only two decimal digits
        const parts = newText.split('.');
        if (parts.length > 1) {
          newText = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
      }
    }
  }
  return newText;
};

export const allowNaturalNumber = (text) => {
  // Allowed only natural numbers (no digits after the decimal point).
  let newText = text;
  if (newText && newText.toString().length) {
    newText = newText.toString();
    // Remove any non-digit characters and dots
    newText = newText.replace(/[^\d]/g, ''); // Remove non-digit characters
    newText = newText.replace(/\./g, ''); // Remove dots
  }
  return newText;
};

export const currentData = () => {
  return new Date().toISOString().split('T')[0];
};

export const calculateRelativePercentage = (value1 = 0, value2 = 0) => {
  if (parseInt(value1) > parseInt(value2)) {
    return Math.round(100 - (100 * parseInt(value2)) / parseInt(value1));
  } else if (value1 < value2) {
    return Math.round(100 - (100 * parseInt(value1)) / parseInt(value2));
  } else {
    return 0;
  }
};

export const calculatePercentage = (value1 = 0, value2 = 0) => {
  if (parseInt(value1) > 0 || parseInt(value2) > 0) {
    return Math.round((100 * parseInt(value1)) / (parseInt(value1) + parseInt(value2)));
  }
  return 0;
};

export const calculateLeverPercentage = (totalCount, value) => {
  if (parseInt(totalCount) > 0 || parseInt(value) > 0) {
    return Math.round((100 * parseInt(value)) / parseInt(totalCount));
  }
  return 0;
};

export const currancyFormatter = (value) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(value);
};

export const addEuroSymbol = (value) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  });
  return formatter.format(value);
};

export const numberFormatter = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(value);
};

export const isDashboardBottomCardVisible = () => {
  // return !!(
  //   // moment(
  //   //   moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')).isBetween(
  //   //   startDate,
  //   //   endDate,
  //   //   undefined,
  //   //   '[]'
  //   // ) &&
  //   (moment(endDate).diff(moment(startDate), 'days') < 8)
  // );
  return true;
};

export const validateUrl = (url) => {
  const regex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return regex.test(url);
};

export const validatePostCode = (postCode) => {
  const regex =
    /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/gim;
  return regex.test(postCode);
};

export const validateAlphaNumericPostCode = (text) => {
  const regex = /^[A-Z0-9]{2,4}$/;
  return regex.test(text);
};
export const validateAlphaNumericPostCodeForWorker = (text) => {
  const regex = /^(?!\s)[A-Za-z0-9 ]{3,8}(?<!\s)$/;
  return regex.test(text);
};
export const filterErrorData = (message) => {
  return message.split('\n').map((line, index) => <p key={index}>{line}</p>);
};

export const checkLanguage = (text) => {
  const regex = /\((.*?)\)/g;
  return text.match(regex);
};
export const calculateTrendPercentage = (value1, value2) => {
  const diff = parseInt(value1) - parseInt(value2);
  if (value1 === 0 && value2 === 0) {
    return 0;
  } else if (value1 === 0 || value2 === 0) {
    return 100;
  } else {
    return Math.abs(Math.round((diff / parseInt(value2)) * 100));
  }
};

export const currencySymbol = (value) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
  return formatter.format(value);
};

export const currencySymbolWithDecimal = (value) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return formatter.format(value);
};

export const calculatePercentageWithDecimalPoints = (value1 = 0, value2 = 0) => {
  if (parseInt(value1) > 0 || parseInt(value2) > 0) {
    return numberFormatter((100 * parseInt(value1)) / (parseInt(value1) + parseInt(value2)));
  }
  return 0;
};

export const numberRounder = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return formatter.format(value);
};
